<template>
    <div class="left-complications">
    
        <a v-if="!$store.state.user" target="_blank" href="https://t.me/fractionalrobots"><img style="        clip-path: polygon(100% 0, 100% calc(100% - 0.7rem), calc(100% - 0.7rem) 100%, 0 100%, 0 0);
                                                                                                                                                                                                                                                                                                                                        background:#ffd300;padding:1rem;width:5rem;margin:0rem;" src="~/@/assets/telegram.svg" alt=""></a>
    
        <div class="exit" v-if="$store.state.user">
            <div @click="$store.dispatch('logout')">
                <h3><b>E</b>X<b>I</b><b>T</b></h3>
            </div>
        </div>
    
    
        <div class="menu" v-if="$store.state.user">
    
            <router-link to="/">RECIPE</router-link>
            <router-link to="/home">PARTS</router-link>
        </div>
    
        <a v-if="$store.state.user" target="_blank" href="https://t.me/fractionalrobots"><img class="tg" src="~/@/assets/telegram.svg" alt=""></a>
    
        <p class="clocktext">
            FRACTIONAL ROBOTS <br> {{ dateTime }}</p>
    
    </div>
    
    <router-view/>
    
    
    <div class="right-complications">
    
    </div>
    <div class="grid">
        <div class="vertical-raster-lines line-1"></div>
        <div class="vertical-raster-lines line-2"></div>
        <div class="vertical-raster-lines line-3"></div>
        <div class="vertical-raster-lines line-4"></div>
        <div class="vertical-raster-lines line-5"></div>
        <div class="vertical-raster-lines line-6"></div>
        <div class="vertical-raster-lines line-7"></div>
        <div class="vertical-raster-lines line-8"></div>
        <div class="vertical-raster-lines line-9"></div>
        <div class="vertical-raster-lines line-10"></div>
        <div class="vertical-raster-lines line-11"></div>
        <div class="vertical-raster-lines line-12"></div>
        <div class="vertical-raster-lines line-13"></div>
        <div class="vertical-raster-lines line-14"></div>
        <div class="vertical-raster-lines line-15"></div>
        <div class="vertical-raster-lines line-16"></div>
        <div class="vertical-raster-lines line-17"></div>
        <div class="vertical-raster-lines line-18"></div>
        <div class="vertical-raster-lines line-19"></div>
        <div class="vertical-raster-lines line-20"></div>
        <div class="vertical-raster-lines line-21"></div>
        <div class="vertical-raster-lines line-22"></div>
        <div class="vertical-raster-lines line-23"></div>
    </div>
</template>

<script>
import { onBeforeMount } from 'vue'
import { useStore } from 'vuex'

export default {
    setup() {
        const store = useStore()

        onBeforeMount(() => {
            store.dispatch('fetchUser')
        })
    },
    data() {
        return {
            dateTime: null,
            menu: false,
        }
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
        this.timer = setInterval(() => {
            this.dateTime = this.currentDateTime();
        }, 1000);
        this.timer = setInterval(() => {
            this.reqDateTime = this.requestedDateTime();
        }, 1000);

    },
    methods: {

        currentDateTime() {
            const current = new Date();
            const date = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
            const time = current.getHours() + ':' + current.getMinutes() + ':' + current.getSeconds();
            const dateTime = date + ' ' + time;
            return dateTime;
        },
        requestedDateTime() {
            const current = new Date();
            const date = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
            const time = current.getHours() + ':' + current.getMinutes() + ':' + current.getSeconds();
            const reqDateTime = date + ' ' + time;
            return reqDateTime;
        },
    }

}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');
@font-face {
    font-family: Mechsuit;
    src: url(./assets/fonts/Mechsuit.otf);
}

.tg {
    clip-path: polygon(100% 0, 100% calc(100% - 0.7rem), calc(100% - 0.7rem) 100%, 0 100%, 0 0);
    background: #ffd300;
    padding: 1rem;
    width: 5rem;
    position: absolute;
    margin: 0rem;
    left: 10rem;
}

.grid {
    position: fixed;
    top: 0px;
    opacity: 1;
    height: 100vh;
    pointer-events: none;
    z-index: 4;
    width: 100vw;
    left: 0px;
    background: repeating-linear-gradient(180deg, transparent, transparent calc(8.33333vw/2 - 1px), rgba(0, 0, 0, .5) calc(8.33333vw/2 - 1px), rgba(0, 0, 0, .5) calc(8.33333vw/2));
}

.vertical-raster-lines {
    position: absolute;
    height: 100%;
    top: 0;
    mix-blend-mode: color-burn;
    background-color: rgba(0, 0, 0, .5);
    width: 1px;
}

.line-1 {
    left: 4.166665%;
}

.line-2 {
    left: calc(4.166665%*2);
}

.line-3 {
    left: calc(4.166665%*3);
}

.line-4 {
    left: calc(4.166665%*4);
}

.line-5 {
    left: calc(4.166665%*5);
}

.line-6 {
    left: calc(4.166665%*6);
}

.line-7 {
    left: calc(4.166665%*7);
}

.line-8 {
    left: calc(4.166665%*8);
}

.line-9 {
    left: calc(4.166665%*9);
}

.line-10 {
    left: calc(4.166665%*10);
}

.line-11 {
    left: calc(4.166665%*11);
}

.line-12 {
    left: calc(4.166665%*12);
}

.line-13 {
    left: calc(4.166665%*13);
}

.line-14 {
    left: calc(4.166665%*14);
}

.line-15 {
    left: calc(4.166665%*15);
}

.line-16 {
    left: calc(4.166665%*16);
}

.line-17 {
    left: calc(4.166665%*17);
}

.line-18 {
    left: calc(4.166665%*18);
}

.line-19 {
    left: calc(4.166665%*19);
}

.line-20 {
    left: calc(4.166665%*20);
}

.line-21 {
    left: calc(4.166665%*21);
}

.line-22 {
    left: calc(4.166665%*22);
}

.line-23 {
    left: calc(4.166665%*23);
}

h1,
h2,
h3 {
    font-family: Mechsuit;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

@keyframes crtoutput {
    0% {
        opacity: 0.85;
    }
    100% {
        opacity: 1;
    }
}

@keyframes borderstretch {
    0% {
        min-width: 1rem;
        min-height: 1.5rem;
    }
    50% {
        min-width: 1.5rem;
        min-height: 1rem;
    }
    100% {
        min-width: 1rem;
        min-height: 1.5rem;
    }
}

#app {
    z-index: 5;
    position: relative;
    font-family: 'Rajdhani', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: white;
    background-image: url(./assets/concrete-wall.png);
    height: 100vh;overflow-y:auto;overflow-x:hidden;
    &:before {
        animation: crtoutput 10ms infinite;
        content: '';
        mix-blend-mode: multiply;
        width: 100vw;
        height: 100vh;
        display: flex;
        position: fixed;
        background-size: auto;
        background-image: radial-gradient( #ffd30033, #ffb10022, black, black);
    }
}

.clocktext {
    text-align: left;
    position: fixed;
    width: 10rem;
    height: 3rem;
    padding-left: 0.5rem;
    left: 0rem;
    top: calc(100vh - 3rem);
    z-index: 5;
    font-weight: bold;
    color: #ffd300;
    &:after {
        content: '';
        width: 10rem;
        height: 2rem;
        position: absolute;
        background-position: left;
        top: -2.5rem;
        left: 0rem;
        background-repeat: no-repeat;
        background-image: url(./assets/caution.svg);
        background-size: 10rem;
    }
    &:before {
        content: '';
        position: absolute;
        background-position: top left;
        bottom: 0.5rem;
        right: 0rem;
        background-image: linear-gradient(135deg, transparent, transparent calc(100% - 0.5rem), #ffd300 calc(100% - 0.5rem), #ffd300 100%);
        clip-path: polygon(100% 0, 100% calc(100% - 0.7rem), calc(100% - 0.7rem) 100%, 0 100%, 0 0);
        border-bottom: 1px solid #ffd300;
        border-right: 1px solid #ffd300;
        animation: borderstretch 1s ease infinite;
    }
}

body {
    /*background-image: url(./assets/city.jpg);*/
    background-size: cover;
    background-color: black;
}

.left-complications {
    display: flex;
    position: fixed;
    z-index: 100;
    width: 10rem;
    height: 0px;
    left: 0px;
    top: 0px;
    z-index: 15;
    height: 13rem;
    border-right: 2px solid #ffd300;
    &:after {
        content: '';
        position: absolute;
        right: -2.8px;
        bottom: 0.5px;
        height: 10rem;
        width: 3px;
        background-color: #ffd300;
        transform-origin: bottom center;
        transform: rotate(-135deg);
        border-radius: 1rem;
    }
    &:before {
        border-radius: 1rem;
        content: '';
        position: absolute;
        left: 2.87rem;
        bottom: -6.9rem;
        height: calc(100vh - 25.5rem);
        width: 2px;
        background-image: linear-gradient(to top, #ffd300, transparent);
        transform-origin: bottom center;
        transform: rotate(-180deg);
    }
    .menu {
        z-index: 10;
        display: flex;
        flex-flow: column;
        top: 5rem;
        position: absolute;
        width: 100%;
        text-align: center;
        transition: all 0.5s ease;
        a {
            transition: all 0.5s ease;
            font-weight: bold;
            width: 100%;
            padding: 1rem;
            line-height: 2rem;
            color: #ffd300;
            &:hover {
                text-shadow: 0px 0px 5px;
                &:after {
                    content: '';
                    position: absolute;
                    left: 1rem;
                    height: 1.5rem;
                    width: 8px;
                    margin-top: 0.25rem;
                    border-left: 1px dotted #ffd300aa;
                    border-top: 1px solid #ffd300;
                    border-bottom: 1px solid #ffd300;
                }
                &:before {
                    content: '';
                    position: absolute;
                    right: 1rem;
                    height: 1.5rem;
                    width: 8px;
                    margin-top: 0.25rem;
                    border-right: 1px dotted #ffd300aa;
                    border-top: 1px solid #ffd300;
                    border-bottom: 1px solid #ffd300;
                }
            }
            &:after {
                transition: all 0.5s ease;
                content: '';
                position: absolute;
                left: 1rem;
                height: 2rem;
                width: 2px;
                border-left: 1px dotted #ffd300aa;
                border-top: 1px solid #ffd300;
                border-bottom: 1px solid #ffd300;
            }
            &:before {
                transition: all 0.5s ease;
                content: '';
                position: absolute;
                right: 1rem;
                height: 2rem;
                width: 2px;
                border-right: 1px dotted #ffd300aa;
                border-top: 1px solid #ffd300;
                border-bottom: 1px solid #ffd300;
            }
        }
    }
}

.headi {
    position: absolute;
    top: 2rem;
    right: 2rem;
    width: auto;
    height: 4rem;
}

.debug {
    position: absolute;
    bottom: 0px;
    width: 13rem;
    bottom: 0.5rem;
    left: -3.5rem;
}

.a-dialog {
    height: auto !important;
}

.a-dialog-button {
    height: 48px !important;
}

.a-orientation-modal {
    font-family: 'Rajdhani', sans-serif !important;
}

.a-orientation-modal>button {
    background: unset !important;
    width: 100vw !important;
    height: 100vh !important;
}

.a-loader-title {
    color: #FFD400;
    opacity: 0;
}

.lense {
    width: 48px;
    height: 48px;
    margin: 8px;
}

.vr {
    width: 64px;
    height: 64px;
}

.a-dialog {
    border-radius: 1rem !important;
    font-family: 'Rajdhani', sans-serif !important;
}

.a-dialog-allow-button {
    font-family: 'Rajdhani', sans-serif !important;
    font-weight: bold !important;
    color: black !important;
    background-color: #FFD400 !important;
    border-radius: 0.5rem !important;
}

.a-dialog-deny-button {
    font-family: 'Rajdhani', sans-serif !important;
    font-weight: bold !important;
    color: black !important;
    background-color: white !important;
    border-radius: 0.5rem !important;
    ;
}

.a-dialog-ok-button {
    background-color: green !important;
    border-radius: 0.5rem !important;
    ;
}

.exit {
    z-index: 100;
    color: white;
    line-height: 0.8rem;
    cursor: pointer;
    left: 1rem;
    width: 8rem;
    height: 3rem;
    top: 1rem;
    position: absolute;
    transition: all 0.5s ease;text-align:center;
    &:hover {
        div {
            &::before {
                transition: all 0.5s ease;
                content: '';
                bottom: 0px;
                right: 0px;
                min-height: 0.5rem;
                min-width: 0.5rem;
                z-index: 20;
                display: flex;
                position: absolute;
                border-bottom: 1px solid #ffd300;
                border-right: 1px solid #ffd300;
            }
            &::after {
                transition: all 0.5s ease;
                content: '';
                top: 0px;
                left: 0px;
                min-height: 0.5rem;
                min-width: 0.5rem;
                z-index: 20;
                display: flex;
                position: absolute;
                border-top: 1px solid #ffd300;
                border-left: 1px solid #ffd300;
            }
            h3 {
                margin-top: 0rem;
                background: #ffd300;
                color: black;
                padding: 1rem;
                padding-bottom: 1.2rem;
                &::before {
                    content: '';
                    top: 0px;
                    left: 0px;
                    min-height: 0.5rem;
                    min-width: 0.5rem;
                    z-index: 20;
                    display: flex;
                    position: absolute;
                    border-top: 1px solid #ffd300;
                    border-bottom: 1px solid #ffd300;
                    border-left: 1px solid #ffd300;
                }
                &::after {
                    content: '';
                    bottom: 0px;
                    right: 0px;
                    min-height: 0.5rem;
                    min-width: 0.5rem;
                    z-index: 20;
                    display: flex;
                    position: absolute;
                    border-top: 1px solid #ffd300;
                    border-bottom: 1px solid #ffd300;
                    border-right: 1px solid #ffd300;
                }
            }
        }
    }
    div {
        &::before {
            transition: all 0.5s ease;
            content: '';
            bottom: 0px;
            right: 0px;
            min-height: 0.5rem;
            min-width: 0.5rem;
            z-index: 20;
            display: flex;
            position: absolute;
            border-bottom: 1px solid white;
            border-right: 1px solid white;
        }
        &::after {
            transition: all 0.5s ease;
            content: '';
            top: 0px;
            left: 0px;
            min-height: 0.5rem;
            min-width: 0.5rem;
            z-index: 20;
            display: flex;
            position: absolute;
            border-top: 1px solid white;
            border-left: 1px solid white;
        }
        h3 {
            transition: all 0.5s ease;
            padding-bottom: 1.2rem;
            padding: 0rem;
            margin-top: 1rem;
            &::before {
                transition: all 0.5s ease;
                content: '';
                bottom: 0px;
                left: 0px;
                min-height: 0.5rem;
                min-width: 0.5rem;
                z-index: 20;
                display: flex;
                position: absolute;
                border-bottom: 1px solid white;
                border-left: 1px solid white;
            }
            &::after {
                transition: all 0.5s ease;
                content: '';
                top: 0px;
                right: 0px;
                min-height: 0.5rem;
                min-width: 0.5rem;
                z-index: 20;
                display: flex;
                position: absolute;
                border-top: 1px solid white;
                border-right: 1px solid white;
            }
        }
    }
}

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: white;
}

#nav a.router-link-exact-active {
    color: #42b983;
}

@media (max-width: 1024px) {
    .clocktext {
        text-align: right;
        position: absolute;
        top: 1rem;
        padding: 0.5rem;
        line-height: 1rem;
        font-size: 1rem;
        height: 3rem;
        right: 1rem;
        left: unset;
        background-image: linear-gradient(-135deg, black, black calc(100% - 0.5rem), #ffd300 calc(100% - 0.5rem), #ffd300 100%);
        color: #ffd300;
        &:after {
            content: '';
            position: absolute;
            left: -0.5rem;
            top: -0.5rem;
            height: 4rem;
            width: 2px;
            border-top: 1px solid black;
            border-bottom: 1px solid black;
            animation: unset;
        }
        &:before {
            content: '';
            display: none;
        }
    }
    .tg {
        clip-path: polygon(100% 0, 100% calc(100% - 0.7rem), calc(100% - 0.7rem) 100%, 0 100%, 0 0);
        background: #ffd300;
        padding: 1rem;
        width: 5rem;
        position: absolute;
        margin: 0rem;
        left: 10rem;
    }
    .left-complications {
        display: flex;
        position:relative;
        flex-flow: row;
        justify-content: space-between;
        width: 100vw;z-index: 100;
        height: 5rem;
        background-color: #ffd300;
        &:after {
            content: '';
            position: absolute;
            right: -3px;
            bottom: 1px;
            height: 5rem;
            width: 3px;
            display:none;
            background-color: unset;
            background-image: linear-gradient(-135deg, transparent, #ffd300);
            transform-origin: bottom center;
            transform: rotate(-135deg);
            border-radius: 1rem;
        }
        &:before {
            content: '';
            position: absolute;
            right: unset;
            left: -1px;
            bottom: 1px;
            height: 5rem;
            width: 3px;
            display:none;
            background-color: unset;
            background-image: linear-gradient(135deg, transparent, #ffd300);
            transform-origin: bottom center;
            transform: rotate(135deg);
            border-radius: 1rem;
        }
        .menu {
            z-index: 10;
            display: flex;
            top: 1rem;
            position: absolute;
            width: auto;
            left: 5rem;
            text-align: center;
            a {
                line-height: 1.25rem;
                text-align: center;
                margin-bottom: 0.5rem;
                height: 1.25rem;
                text-align: center !important;
                width: 5rem;
                display: flex;
                flex-flow: row;
                background: black;
                color: #ffd300;
                padding: unset;
                justify-content: center;
                &:hover {
                    text-shadow: unset !important;
                    &:after {
                        content: '';
                        display: none;
                    }
                    &:before {
                        content: '';
                        display: none;
                    }
                }
                &:after {
                    content: '';
                    display: none;
                }
                &:before {
                    content: '';
                    display: none;
                }
            }
        }
    }
    .headi {
        position: absolute;
        top: 1rem;
        right: unset;
        left: 7rem;
        width: auto;
        height: 4rem;
    }
    .exit {
        z-index: 100;
        color: black;
        line-height: 0.8rem;
        cursor: pointer;
        left: 1rem;
        width: 3rem;
        height: 3rem;
        top: 1rem;
        position: absolute;
        transition: all 0.5s ease;
        b {
            display: none;
        }
        &:hover {
            div {
                max-width: 48px !important;
                &::before {
                    transition: all 0.5s ease;
                    content: '';
                    bottom: 0px;
                    right: 0px;
                    min-height: 0.5rem;
                    min-width: 0.5rem;
                    z-index: 20;
                    display: flex;
                    position: absolute;
                    border-bottom: 1px solid #ffd300;
                    border-right: 1px solid #ffd300;
                }
                &::after {
                    transition: all 0.5s ease;
                    content: '';
                    top: 0px;
                    left: 0px;
                    min-height: 0.5rem;
                    min-width: 0.5rem;
                    z-index: 20;
                    display: flex;
                    position: absolute;
                    border-top: 1px solid #ffd300;
                    border-left: 1px solid #ffd300;
                }
                h3 {
                    max-width: 48px;
                    transition: all 0.5s ease;
                    margin-top: 0rem;
                    background: #ffd300;
                    color: black;
                    padding: 1rem;
                    margin-left: -0.25rem;
                    padding-bottom: 1.2rem;
                    &::before {
                        content: '';
                        top: 0px;
                        left: 0px;
                        min-height: 0.5rem;
                        min-width: 0.5rem;
                        z-index: 20;
                        display: flex;
                        position: absolute;
                        border-top: 1px solid #ffd300;
                        border-bottom: 1px solid #ffd300;
                        border-left: 1px solid #ffd300;
                    }
                    &::after {
                        content: '';
                        bottom: 0px;
                        right: 0px;
                        min-height: 0.5rem;
                        min-width: 0.5rem;
                        z-index: 20;
                        display: flex;
                        position: absolute;
                        border-top: 1px solid #ffd300;
                        border-bottom: 1px solid #ffd300;
                        border-right: 1px solid #ffd300;
                    }
                }
            }
        }
        div {
            max-width: 48px !important;
            &::before {
                transition: all 0.5s ease;
                content: '';
                bottom: 0px;
                right: 0px;
                min-height: 0.5rem;
                min-width: 0.5rem;
                z-index: 20;
                display: flex;
                position: absolute;
                border-bottom: 1px solid black;
                border-right: 1px solid black;
            }
            &::after {
                transition: all 0.5s ease;
                content: '';
                top: 0px;
                left: 0px;
                min-height: 0.5rem;
                min-width: 0.5rem;
                z-index: 20;
                display: flex;
                position: absolute;
                border-top: 1px solid black;
                border-left: 1px solid black;
            }
            h3 {
                max-width: 48px;
                transition: all 0.5s ease;
                margin-top: 0rem;
                background: #ffd300;
                color: black;
                padding: 1rem;
                margin-left: -0.25rem;
                padding-bottom: 1.2rem;
                &::before {
                    transition: all 0.5s ease;
                    content: '';
                    bottom: 0px;
                    left: 0px;
                    min-height: 0.5rem;
                    min-width: 0.5rem;
                    z-index: 20;
                    display: flex;
                    position: absolute;
                    border-bottom: 1px solid black;
                    border-left: 1px solid black;
                }
                &::after {
                    transition: all 0.5s ease;
                    content: '';
                    top: 0px;
                    right: 0px;
                    min-height: 0.5rem;
                    min-width: 0.5rem;
                    z-index: 20;
                    display: flex;
                    position: absolute;
                    border-top: 1px solid black;
                    border-right: 1px solid black;
                }
            }
        }
    }
}
</style>
